import Layout from "@/components/Layout";
import React, { useEffect, useRef } from "react";
import { useLocation } from "@reach/router";
import "../../styles/flickity.css";
const Greeting = ({ id }: any) => {
  const newYear = [
    {
      letter: "Ш",
    },
    {
      letter: "И",
    },
    {
      letter: "Н",
    },
    {
      letter: "Э",
    },
    {
      letter: " ",
    },
    {
      letter: "О",
    },
    {
      letter: "Н",
    },
    {
      letter: "Ы",
    },
    {
      letter: " ",
    },
    {
      letter: "М",
    },
    {
      letter: "Э",
    },
    {
      letter: "Н",
    },
    {
      letter: "Д",
    },
    {
      letter: " ",
    },
    {
      letter: "Х",
    },
    {
      letter: "Ү",
    },
    {
      letter: "Р",
    },
    {
      letter: "Г",
    },
    {
      letter: "Э",
    },
    {
      letter: "Е",
    },
  ];
  const companys = [
    { id: "1", name: "MonPay" },
    { id: "2", name: "CU" },
    { id: "3", name: "ARD Holdings" },
    { id: "4", name: "U-Point" },
    { id: "5", name: "E-School" },
    { id: "6", name: "Vertexmon" },
    { id: "7", name: "SMP" },
    { id: "8", name: "Steppe Copper" },
    { id: "9", name: "Prime Cineplex" },
    { id: "10", name: "Capitron Bank" },
    { id: "11", name: "" },
  ];
  const [clickButton, setClickButton] = React.useState(false);
  const soundRef = useRef<any>();
  const soundAiRef = useRef<any>();

  const router = useLocation();

  const params: any = (router?.pathname || "")?.split(`/`);
  const results = params.filter((element: any) => {
    return element !== "";
  });

  useEffect(() => {
    if (clickButton) {
      const timer = setTimeout(async () => {
        if (soundAiRef.current && soundRef.current) {
          soundRef.current?.play();
          soundAiRef.current?.play();
        }
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [clickButton]);

  return (
    <Layout title="Greeting" light={true} snow="disSnow" ContactUsBtn={false}>
      <audio id="s1" ref={soundRef}>
        <source src={"../../audio/bg.mp3"} type="audio/mpeg" />
      </audio>
      <audio id="s2" ref={soundAiRef}>
        <source src={"../../audio/ai.wav"} type="audio/wav" />
      </audio>

      {clickButton ? (
        <div className="pt-10 lg:pt-5 space-y-10 lg:space-y-5 text-center">
          <div className="px-5 lg:px-0 text-tilt-shake space-y-3 lg:space-y-1.5">
            {newYear.map((item: any, index: number) => (
              <span
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay={`${index * 100}`}
                className="text-2xl lg:text-3xl font-serif"
              >
                {item?.letter}
              </span>
            ))}

            <div className=" text-md lg:text-lg font-serif">
              Хамтран ажилласанд талархаж 2023 онд амжилт бүтээлээр дүүрэн
              байхыг ерөөе!
            </div>
          </div>

          <div className="snowglobe" id="mydiv">
            <div className="sg-bg"></div>
            <div className="grnd-snow">
              <div className="snow-surface"></div>
            </div>
            <div className="tree">
              <div className="tree-shadow"></div>
              <div className="branch-bot">
                <div className="branch-bot-snow"></div>
                <div className="branch-shadow"></div>
              </div>
              <div className="branch-mid">
                <div className="branch-mid-snow"></div>
                <div className="branch-shadow"></div>
              </div>
              <div className="branch-top">
                <div className="branch-top-snow"></div>
              </div>
              <div className="star"></div>
              <div className="baubles"></div>
            </div>
            <div className="sm-shadow"></div>
            <div className="snowman">
              <div className="snowman-bot"></div>
              <div className="snowman-top"></div>
              <div className="coal"></div>
              <div className="carrot"></div>
            </div>
            <div className="falling-snow-wrap">
              <div className="falling-snow">
                <div className="flakes1">
                  <p>*</p>
                </div>
                <div className="flakes2">
                  <p>*</p>
                </div>
              </div>
            </div>
            <div className=" sg-fg"></div>
            <div className="company absolute bottom-12 z-50  font-bold text-white  px-10 w-72 ml-2 text-[22px] ">
              {companys[Number.parseInt(results.pop(), 10) - 1]?.name || ""}
            </div>
            <div className="stand-curved-wrap">
              <div className="stand-curved"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center mt-60 lg:mt-52 cursor-pointer">
          <div className="centerer " onClick={() => setClickButton(true)}>
            <a className="button">Мэндчилгээ</a>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Greeting;
